// const baseURL = 'http://10.12.41.17:8080'
const baseURL = 'http://172.16.50.238:7020/manage'
// 获取列表接口
const getBillTypeConfigListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeConfigPage`
// 查询数据接口
const queryURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeConfigDetail`
// 提交数据接口
const updateURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/updateBilltypeConfig`
// 新增数据接口
const createURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/addBilltypeConfig`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/delBilltypeConfig`
// 获取单据类型列表
const getBilltypeListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeSelect2`
// 获取模型流程列表
const getProcessListURL = `${API_CONFIG.butlerBaseURL}actProcessIns/deployment/select2`

export {
    getBillTypeConfigListURL,
    queryURL,
    updateURL,
    createURL,
    deleteURL,
    getBilltypeListURL,
    getProcessListURL

}