<template>
  <div class="bill-type-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="单据类型" :rules="[{ required: true, message: '请选择单据类型', trigger: 'change' }]" prop="billtypeId">
            <v-select2 
                v-model="form.billtypeId"
                placeholder="单据类型"
                v-bind="billTypeParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="所属组织" :rules="[{ required: true, message: '请选择所属组织', trigger: 'change' }]" prop="orgId">
            <v-select2 
                v-model="form.orgId"
                placeholder="所属组织"
                v-bind="orgParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="关联流程" :rules="[{ required: true, message: '请选择关联流程', trigger: 'change' }]" prop="processId">
            <v-select2 
                v-model="form.processId"
                placeholder="关联流程"
                v-bind="processParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="配置状态"  prop="isEnable">
            <v-select v-model="form.isEnable" :options="billTypeConfigOps" />
          </el-form-item>

        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL, createURL, updateURL, getBilltypeListURL, getProcessListURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { billTypeConfigOps } from './map'
import { orgParams } from 'common/select2Params'

export default {
  name: 'billTypeConfigForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {

    return {
      billTypeConfigOps: billTypeConfigOps(1),
      width: 200,
      isLoad: false,
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: undefined,
        submitMethod: ''
      },
      form: {
        id: undefined,
        billtypeId: '',
        isEnable: 1,
        orgId: 0,
        processId: ''
      },
      orgParams,
      billTypeParams : {
        searchUrl: getBilltypeListURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'billtypeName',
          value: 'id'
        },
      },
      processParams: {
        searchUrl: getProcessListURL,
        request: {
          text: 'name',
          value: 'id'
        }
      },
      
    }
  },
  
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.form.id = id
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'POST'
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
    }
    this.isLoad = true
  },
  methods: {
    update (data) {
      this.form.billtypeId = data.billtypeId
      this.form.processId = data.processId
      this.form.isEnable = data.isEnable
      this.form.orgId = data.orgId
    },
    submitBefore (data) {
        return data
      
    }
  }
}
</script>
